import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
// import { SiteMetadataQuery } from 'gatsby'
import Header from './header'
import Footer from './footer'

interface LayoutProps {
  footer?: boolean
  header?: boolean
  readonly children?: React.ReactNode | readonly React.ReactNode[]
}

const Layout: React.FC<LayoutProps> = ({ children, header, footer }) => {
  const data = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
          twitterUsername
        }
      }
    }
  `)

  const image = `${data.siteUrl}${data.image || ''}`

  return (
    <>
      <Helmet titleTemplate={`%s - ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title}>
        <html lang="en" />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta
          name="keywords"
          content="biden, joe biden, bernie, sanders, vs, joe vs bernie, biden vs sanders, joe biden vs bernie sanders,best democrat, vote"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />

        <meta name="twitter:card" content="summary_large_image" />
        {data.twitterUsername && <meta name="twitter:creator" content={data.twitterUsername} />}
        {data.title && <meta name="twitter:title" content={data.title} />}
        {data.description && <meta name="twitter:description" content={data.description} />}
        <meta name="twitter:image" content={image} />
        <meta name="twitter:creator" content={data.twitterUsername}></meta>
      </Helmet>
      <body>
        {header && <Header title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />}
        <main>
          <div className="mx-w-lg p-1 m-0">{children}</div>
        </main>
        {footer && <Footer />}
      </body>
    </>
  )
}

export default Layout
