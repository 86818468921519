import React, { useState } from 'react'
import { Link } from 'gatsby'

interface Props {
  title: string
  description: string
}

const Header: React.FC<Props> = ({ title, description }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <div className="">
      <nav className="flex items-center justify-between flex-wrap bg-blue-500 p-6">
        <div className="flex flex-row items-center flex-shrink-0 text-white mr-6">
          <Link to="/" className="flex flex-row align-center font-semibold text-2xl tracking-tight">
            {title}
          </Link>
        </div>
        {/* <div className="block lg:hidden">
          <button
            onClick={() => {
              setMenuOpen(isMenuOpen => !isMenuOpen)
            }}
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-white"
          >
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div> */}

        {/* <div className={`w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto`}> */}
        {/* <div className="text-sm lg:flex-grow"></div> */}
        <Link to="/about/" className="block lg:inline-block lg:mt-0 text-white hover:text-white mr-4">
          About
        </Link>
        {/* <div>
            <a
              href="https://berniesanders.com/"
              className="inline-block text-md px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white mt-4 lg:mt-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate
            </a>
          </div> */}
        {/* </div> */}
      </nav>
    </div>
  )
}

export default Header
