import React from 'react'
import { Link } from 'gatsby'

const Footer: React.FC = () => {
  return (
    <div className="">
      <nav className="flex items-center justify-between flex-wrap bg-blue-600 p-6">
        <div className={`w-full block flex-grow flex items-center w-auto`}>
          <a
            href="https://berniesanders.com/"
            className="inline-block text-md px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Donate
          </a>

          {/* <Link to="/about/" className="block lg:inline-block text-white hover:text-white mr-4">
            About
          </Link> */}
          <div className="text-sm flex-grow"></div>
          <a
            href="https://twitter.com/cantbemorewrong"
            target="_blank"
            rel="noopener noreferrer"
            className="block inline-block text-white hover:text-white mr-4"
          >
            @cantbemorewrong
          </a>
        </div>
      </nav>
    </div>
  )
}

export default Footer
